var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('el-form',{attrs:{"model":_vm.form,"label-width":"90px"}},[_c('el-form-item',{attrs:{"label":"链接名"}},[_c('el-input',{model:{value:(_vm.form.title),callback:function ($$v) {_vm.$set(_vm.form, "title", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"form.title"}})],1),_c('el-form-item',{attrs:{"label":"链接地址"}},[_c('el-input',{attrs:{"placeholder":"输入时请在头部添加http://"},model:{value:(_vm.form.url),callback:function ($$v) {_vm.$set(_vm.form, "url", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"form.url"}})],1),_c('el-form-item',{attrs:{"label":"状态"}},[_c('el-switch',{staticClass:"sw",attrs:{"active-color":"#7781f1","inactive-color":"#ff4949","active-text":"显示","inactive-text":"隐藏","active-value":1,"inactive-value":0},model:{value:(_vm.form.status),callback:function ($$v) {_vm.$set(_vm.form, "status", $$v)},expression:"form.status"}})],1),_c('el-form-item',{attrs:{"label":"排序"}},[_c('el-input-number',{attrs:{"min":1,"max":9999,"label":"描述文字"},model:{value:(_vm.form.sort),callback:function ($$v) {_vm.$set(_vm.form, "sort", $$v)},expression:"form.sort"}})],1),_c('el-form-item',{attrs:{"label":"文字图片"}},[_c('el-upload',{attrs:{"headers":{ token: _vm.token },"limit":1,"name":"thumbnail","action":_vm.imgBaseUrl + '/api/Project/upload',"list-type":"picture-card","multiple":false,"file-list":_vm.fileList_text,"on-success":res => {
                        _vm.success(res, 'text_image');
                    },"on-remove":() => {
                        _vm.remove('text_image');
                    }}},[_c('i',{staticClass:"el-icon-plus"})])],1),_c('el-form-item',{attrs:{"label":"二维码图片"}},[_c('el-upload',{attrs:{"headers":{ token: _vm.token },"limit":1,"name":"thumbnail","action":_vm.imgBaseUrl + '/api/Project/upload',"list-type":"picture-card","multiple":false,"file-list":_vm.fileList_qrcode,"on-success":res => {
                        _vm.success(res, 'qrcode_image');
                    },"on-remove":() => {
                        _vm.remove('qrcode_image');
                    }}},[_c('i',{staticClass:"el-icon-plus"})])],1),_c('el-form-item',{attrs:{"label":"详情"}},[_c('editor',{attrs:{"txt":_vm.setForm.details,"getTxt":txt => {
                        _vm.form.details = txt;
                    }}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }