<template>
    <div>
        <el-form :model="form" label-width="90px">
            <el-form-item label="链接名">
                <el-input v-model.trim="form.title"></el-input>
            </el-form-item>
            <el-form-item label="链接地址">
                <el-input v-model.trim="form.url" placeholder="输入时请在头部添加http://"></el-input>
            </el-form-item>
            <el-form-item label="状态">
                <el-switch
                    class="sw"
                    v-model="form.status"
                    active-color="#7781f1"
                    inactive-color="#ff4949"
                    active-text="显示"
                    inactive-text="隐藏"
                    :active-value="1"
                    :inactive-value="0"
                ></el-switch>
            </el-form-item>
            <el-form-item label="排序">
                <el-input-number v-model="form.sort" :min="1" :max="9999" label="描述文字"></el-input-number>
            </el-form-item>
            <el-form-item label="文字图片">
                <el-upload
                    :headers="{ token: token }"
                    :limit="1"
                    name="thumbnail"
                    :action="imgBaseUrl + '/api/Project/upload'"
                    list-type="picture-card"
                    :multiple="false"
                    :file-list="fileList_text"
                    :on-success="
                        res => {
                            success(res, 'text_image');
                        }
                    "
                    :on-remove="
                        () => {
                            remove('text_image');
                        }
                    "
                >
                    <i class="el-icon-plus"></i>
                </el-upload>
            </el-form-item>
            <el-form-item label="二维码图片">
                <el-upload
                    :headers="{ token: token }"
                    :limit="1"
                    name="thumbnail"
                    :action="imgBaseUrl + '/api/Project/upload'"
                    list-type="picture-card"
                    :multiple="false"
                    :file-list="fileList_qrcode"
                    :on-success="
                        res => {
                            success(res, 'qrcode_image');
                        }
                    "
                    :on-remove="
                        () => {
                            remove('qrcode_image');
                        }
                    "
                >
                    <i class="el-icon-plus"></i>
                </el-upload>
            </el-form-item>
            <el-form-item label="详情">
                <editor
                    :txt="setForm.details"
                    :getTxt="
                        txt => {
                            form.details = txt;
                        }
                    "
                ></editor>
            </el-form-item>
        </el-form>
    </div>
</template>

<script>
import Cookies from "js-cookie";
import axios from "axios";
import editor from "@/components/customs/editor.vue";

export default {
    components: { editor },
    props: {
        setForm: Object,
    },
    data() {
        return {
            form: {
                title: "",
                sort: 1,
                status: 1,
                details: "",
                text_image: "",
                qrcode_image: "",
                url: "",
            },
            fileList_text: [],
            fileList_qrcode: [],
            token: "",
        };
    },
    created() {
        this.token = Cookies.get("token");

        if (this.setForm.id) {
            let f = this.setForm;
            this.form = { ...f };
            this.fileList_qrcode = f.qrcode_image ? [{ name: "", url: f.qrcode_image }] : [];
            this.fileList_text = f.text_image ? [{ name: "", url: f.text_image }] : [];
        }
    },
    methods: {
        getForm() {
            return {
                ...this.form,
            };
        },
        // 图片上传成功后
        success(url, key) {
            this.form[key] = axios.defaults.baseURL + url;
        },
        // 移除图片时
        remove(key) {
            this.form[key] = "";
        },
    },
};
</script>

<style lang="less" scoped>
.sw {
    /deep/.el-switch__label.is-active {
        color: #7781f1 !important;
    }
}
</style>
