<template>
    <div>
        <div class="page">
            <div class="page_header">
                <el-breadcrumb separator="/">
                    <el-breadcrumb-item>底部链接</el-breadcrumb-item>
                    <el-breadcrumb-item>列表分类</el-breadcrumb-item>
                </el-breadcrumb>
            </div>
            <div class="page_headerSecondary">
                <div>
                    <el-select v-model="value" clearable placeholder="选择分类名" @change="onChange">
                        <el-option v-for="el in selectList" :key="el.id" :label="el.title" :value="el.id"></el-option>
                    </el-select>
                </div>
                <div>
                    <!-- <button @click="openAdd({ pid: '占位文字，无任何用' })">添加一级分类</button> -->
                    <VyAddBtn @click.native="openAdd({ pid: '占位文字，无任何用' })" />
                </div>
            </div>
            <!-- 列表 -->
            <div class="page_content">
                <el-table :data="tableData" @row-click="handleRowClick" row-key="id" ref="tab" border style="width: 100%">
                    <el-table-column prop="title" align="center" label="分类名 / 链接名"></el-table-column>
                    <el-table-column prop="text_image" align="center" label="文字图片">
                        <template slot-scope="{ row }">
                            <img :src="row.text_image" alt="" style="width: 100%" />
                        </template>
                    </el-table-column>
                    <el-table-column prop="qrcode_image" align="center" label="二维码图片">
                        <template slot-scope="{ row }">
                            <img :src="row.qrcode_image" alt="" style="width: 100%" />
                        </template>
                    </el-table-column>
                    <el-table-column prop="url" align="center" label="链接"></el-table-column>
                    <el-table-column prop="sort" align="center" label="排序"></el-table-column>
                    <el-table-column prop="status" align="center" label="状态">
                        <template slot-scope="{ row }">
                            <span :style="{ color: row.status ? '#b66dff' : '#fe7c96' }">{{ row.status ? "显示" : "隐藏" }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column align="center" label="操作">
                        <template slot-scope="{ row }">
                            <div class="_add" v-if="row.pid == 0">
                                <button @click="openAdd(row)">添加链接</button>
                            </div>
                            <div class="_operate">
                                <button @click="openSet(row)">编辑</button>
                            </div>
                            <div class="_delete">
                                <button @click="del(row)">删除</button>
                            </div>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </div>
        <!-- 添加或编辑链接弹框 -->
        <el-dialog
            :title="`${sss == 'add' ? '添加' : '编辑'}链接`"
            :visible.sync="dialogShow"
            width="70%"
            :show-close="false"
            :before-close="bfc"
        >
            <ff v-if="dialogShow" :setForm="setForm" ref="ff"></ff>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogShow = false">取 消</el-button>
                <el-button type="primary" @click="yes">确 定</el-button>
            </span>
        </el-dialog>
        <!-- 编辑一级弹框 -->
        <el-dialog title="编辑一级分类" :visible.sync="ds" width="30%" :show-close="false" :before-close="bfc">
            <el-form label-width="90px">
                <el-form-item label="分类名">
                    <el-input v-model.trim="title"></el-input>
                </el-form-item>
                <el-form-item label="状态">
                    <el-switch
                        class="sw"
                        v-model="status"
                        active-color="#7781f1"
                        inactive-color="#ff4949"
                        active-text="显示"
                        inactive-text="隐藏"
                        :active-value="1"
                        :inactive-value="0"
                    ></el-switch>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="ds = false">取 消</el-button>
                <el-button type="primary" @click="qd">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import axios from "axios";
import qs from "qs";
import ff from "./form.vue";
import VyAddBtn from "@/components/customs/VyAddBtn.vue";

export default {
    components: { ff, VyAddBtn },
    data() {
        return {
            tableData: [],
            selectList: [],
            value: "",

            dialogShow: false,
            addPid: null,
            setForm: {},
            sss: "add or set",

            ds: false,
            title: "",
            status: 1,
            id: "",
        };
    },
    async created() {
        let data = await this.getData();
        this.tableData = data;
        this.selectList = data.map(e => {
            return { id: e.id, title: e.title };
        });
    },
    methods: {
        async getData() {
            let { data } = await axios.get("/api/brand_and_bottom/show", { params: { id: this.value } });
            return data;
        },
        // 点击行
        handleRowClick(row, column, event) {
            if (event.target.tagName === "BUTTON") return;
            this.$refs["tab"].toggleRowExpansion(row);
        },
        openSet(row) {
            if (row.pid == 0) {
                // 编辑一级
                this.ds = true;
                this.title = row.title;
                this.status = row.status;
                this.id = row.id;
            } else {
                // 编辑链接
                this.sss = "set";
                this.setForm = { ...row };
                this.dialogShow = true;
            }
        },
        openAdd(row) {
            if (row.pid == 0) {
                // 添加链接
                this.sss = "add";
                this.addPid = row.id;
                this.dialogShow = true;
                this.setForm = {};
            } else {
                // 添加一级
                this.ds = true;
                this.status = 1;
                this.title = "";
                this.id = null;
            }
        },
        // 删除链接
        async del({ id, pid }) {
            let s = await this.$confirm("是否确认删除?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then(() => true)
                .catch(() => false);
            if (!s) return;
            if (pid == 0) {
                // 删除一级
                var { data } = await axios.delete("/api/brand_and_bottom/fdel", { params: { id } });
            } else {
                // 删除链接
                var { data } = await axios.delete("/api/brand_and_bottom/list_del", { params: { id } });
            }
            this.$message({
                type: data.code == 200 ? "success" : "error",
                message: data.msg,
            });
            const d = await this.getData();
            this.tableData = d;
            this.selectList = d.map(e => {
                return { id: e.id, title: e.title };
            });
        },
        // 确认
        async yes() {
            let f = this.$refs["ff"].getForm();
            if (this.sss == "add") {
                var { data } = await axios.post("/api/brand_and_bottom/add", qs.stringify({ ...f, pid: this.addPid }));
            } else if (this.sss == "set") {
                var { data } = await axios.put("/api/brand_and_bottom/update", qs.stringify(f));
            }
            if (data.code !== 200) {
                this.$message.error(data.msg);
            } else {
                this.$message.success(data.msg);
            }
            this.tableData = await this.getData();
            this.dialogShow = false;
        },
        // 编辑或添加一级分类
        async qd() {
            let { id, title, status } = this;
            if (!title) return this.$message.error("请勿为空!");
            if (id) {
                // 编辑
                var { data } = await axios.put("/api/brand_and_bottom/fupdate", qs.stringify({ id, title, status }));
            } else {
                // 添加
                var { data } = await axios.post("/api/brand_and_bottom/fadd", qs.stringify({ title, status }));
            }
            this.$message({
                type: data.code == 200 ? "success" : "error",
                message: data.msg,
            });
            this.ds = false;
            const d = await this.getData();
            this.tableData = d;
            this.selectList = d.map(e => {
                return { id: e.id, title: e.title };
            });
        },
        async onChange() {
            this.tableData = await this.getData();
        },
    },
};
</script>

<style lang="less" scoped>
.page {
    .page_content {
        margin-top: 20px;
        .el-table {
            border-radius: 20px;
        }
    }
    .page_pagination {
        display: flex;
        justify-content: space-between;
        margin-top: 20px;
        /deep/.el-pagination {
            border-radius: 50px;
            overflow: hidden;
            padding: 0px;
        }
    }
    .page_headerSecondary {
        margin-top: 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
}
.sw {
    /deep/.el-switch__label.is-active {
        color: #7781f1 !important;
    }
}
</style>
